body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#basic-nav-dropdown{
  color: #101522;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  height: 80px;
  border-bottom: 2px solid transparent;
  border-radius: 2px;
  &:hover {
      border-bottom: 4px solid #fff;
  }
}

.dropdown-item{
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 2rem;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #ccc;
  background-color: #333;
  margin-top: 20%;
  margin-left: 10%;
  width: 107px;

}
@media screen and (max-width: 991px) {
  .dropdown-item{
    margin-top:5%;
  }
}
